import { forwardRef, useState, useEffect, useRef } from 'react'
import { Marker } from 'react-map-gl'
import { Icon } from '@iqueue/ui-kit'
import cn from 'classnames'
import moment from 'moment'

const StationMarker = ({
  coordinates = [0, 0],
  type = 'tablo',
  status: defaultStatus = 'default',
  onClick,
  error = false,
  station,
}) => {
  const iconRef = useRef()
  const [status, setStatus] = useState(defaultStatus)

  useEffect(() => {
    if (error) {
      setStatus('error')
    } else {
      const lastSeenAt = station?.lastSeenAt
      const stationMeta = station?.meta

      if (stationMeta === null) {
        setStatus('no-meta')
      } else if (lastSeenAt) {
        const inFifteenMinutes = moment(lastSeenAt).isBetween(moment().subtract(15, 'minutes'), moment())
        const beforeOneHour = moment(lastSeenAt).isBefore(moment().subtract(30, 'minutes'))

        if (inFifteenMinutes) setStatus(defaultStatus)
        else if (!inFifteenMinutes && !beforeOneHour) setStatus('warning')
        else setStatus('pulsing-error')
      } else {
        setStatus('no-data')
      }
    }
  }, [error, station, defaultStatus])

  return (
    <Marker
      ref={ iconRef }
      key={ station }
      latitude={ coordinates[0] }
      longitude={ coordinates[1] }
      anchor="center"
      onClick={ onClick }
    >
      <div className={ cn('station-marker', `station-marker-${ status }`) }>
        <Icon>{ 'backup_table' }</Icon>
      </div>
    </Marker>
  )
}

export default forwardRef(StationMarker)
