import React from 'react'
import BasePage from '../components/BasePage'
import Box from '../components/Box'
import withAuth from '../components/WithAuth'
import ListOfLogs from '../components/ListOfLogs'

const Logs = () => (
  <BasePage className="no-paddings no-scroll">
    <Box>
      <ListOfLogs/>
    </Box>
  </BasePage>
)

export default withAuth(Logs, ['admin', 'owner', 'installer', 'tuner'])
