import { forwardRef, useImperativeHandle, useState } from 'react'
import { Modal, useMediaQuery } from '@iqueue/ui-kit'
import cn from 'classnames'

function Dialog({ title, children, opened: handleOpened, onClose, className, ...props }, ref) {
  const fullscreen = useMediaQuery('(max-width: 750px)')
  const [opened, setOpened] = useState(false)

  useImperativeHandle(ref, () => ({
    close: () => setOpened(false),
    open: () => setOpened(true),
  }))

  return (
    <Modal
      title={ title }
      fallback={ true }
      onClose={ onClose || (() => setOpened(false)) }
      fullscreen={ fullscreen }
      isOpened={ opened || handleOpened }
      className={ cn('with-scroll-bars dialog', className) }
      { ...props }
    >
      <div className={ 'dialog-content' }>
        { children }
      </div>
    </Modal>
  )
}

export default forwardRef(Dialog)
