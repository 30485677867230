import React from 'react'
import { Select, Col, Row } from '@iqueue/ui-kit'

function StationInput({ value, cache, onChange, ...props }) {
  const renderStation = (id) => {
    const station = cache.indexed[id] || value
    return {
      key: id,
      title: station?.name,
      subtitle: station?.stationId,
    }
  }

  return (
    <Row>
      <Col size={ 12 }>
        <Select
          entries={ cache.ids }
          disabled={ cache.ids.length === 0 }
          placeholder={ cache.ids.length === 0 ? 'Загрузка...' : 'Остановка' }
          render={ renderStation }
          value={ value?.stationId || '' }
          onChange={ (id) => {
            const selectedStation = cache.indexed[id]
            if (selectedStation) {
              onChange(id)
            } else {
              console.error(`Station not found in cache for ID: ${ id }`)
            }
          } }
          { ...props }
        />
      </Col>
    </Row>
  )
}

export default StationInput
