import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import BasePage from '../components/BasePage'
import Box from '../components/Box'
import { useSessionContext } from '../hooks/UseSession'
import { Button } from '@iqueue/ui-kit'
import withAuth from '../components/WithAuth'

const Inactive = ({ session }) => {
  const navigate = useNavigate()
  const { signOut } = useSessionContext()

  useEffect(() => {
    if (session.enabled) {
      navigate('/', { replace: true })
    }
  }, [session, navigate])

  return (
    <BasePage className="no-paddings no-scroll">
      <Box>
        <h1>Ваш аккаунт был деактивирован администратором!</h1>
        <Button onClick={ signOut } danger>Выйти из аккаунта</Button>
      </Box>
    </BasePage>
  )
}

export default withAuth(Inactive)
