import React, { useCallback, useRef, useEffect } from 'react'
import { Button } from '@iqueue/ui-kit'
import { LngLatBounds } from 'mapbox-gl'
import cn from 'classnames'
import Map from 'react-map-gl'

export default function TashBusStations({
  stations = [],
  interactive = true,
  zoom,
  onStationSelected,
}) {
  const mapRef = useRef(null)

  const fitToStations = useCallback((e, options = {}) => {
    if (!stations.length || !mapRef.current) {
      console.warn('fitToStations: No stations or map not initialized')
      return
    }

    const bounds = stations.reduce((bounds, { lat, lng }) => {
      const location = [lng, lat]
      if (!bounds) {
        return new LngLatBounds(location, location)
      }
      return bounds.extend(location)
    }, null)

    if (bounds) {
      const bottomDivHeight = document.querySelector('.bottom-div')?.offsetHeight || 0

      const padding = {
        top: Math.min(window.outerHeight, window.outerWidth) * 0.1,
        bottom: bottomDivHeight + Math.min(window.outerHeight, window.outerWidth) * 0.3,
        left: Math.min(window.outerHeight, window.outerWidth) * 0.2,
        right: Math.min(window.outerHeight, window.outerWidth) * 0.2,
      }

      try {
        mapRef.current.fitBounds(bounds, { ...options, padding })
      } catch (error) {
        console.error('Error fitting bounds', error)
      }
    }
  }, [stations])

  useEffect(() => {
    if (mapRef.current) {
      const mapInstance = mapRef.current.getMap()

      mapInstance.on('load', () => {
        if (!mapInstance.getSource('stations')) {
          mapInstance.addSource('stations', {
            type: 'geojson',
            data: {
              type: 'FeatureCollection',
              features: stations.map((station) => ({
                type: 'Feature',
                properties: {
                  stationId: station.stationId,
                  name: station.name,
                },
                geometry: {
                  type: 'Point',
                  coordinates: [station.lng, station.lat],
                },
              })),
            },
            cluster: true,
            clusterMaxZoom: 16,
            clusterRadius: 50,
          })

          mapInstance.addLayer({
            id: 'clusters',
            type: 'circle',
            source: 'stations',
            filter: ['has', 'point_count'],
            paint: {
              'circle-color': '#58ac30',
              'circle-radius': [
                'step',
                ['get', 'point_count'],
                20,
                100,
                30,
                750,
                40,
              ],
              'circle-stroke-width': 2,
              'circle-stroke-color': '#58ac30',
            },
          })

          mapInstance.addLayer({
            id: 'cluster-count',
            type: 'symbol',
            source: 'stations',
            filter: ['has', 'point_count'],
            layout: {
              'text-field': '{point_count_abbreviated}',
              'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
              'text-size': 14,
              'text-allow-overlap': true,
            },
            paint: {
              'text-color': '#ffffff',
              'text-halo-color': 'rgba(221,221,221,0.04)',
              'text-halo-width': 4,
            },
          })

          mapInstance.addLayer({
            id: 'unclustered-point',
            type: 'circle',
            source: 'stations',
            filter: ['!', ['has', 'point_count']],
            paint: {
              'circle-color': '#58ac30',
              'circle-radius': 10,
              'circle-stroke-width': 2,
              'circle-stroke-color': '#fff',
            },
          })

          mapInstance.on('click', 'clusters', function (e) {
            const features = mapInstance.queryRenderedFeatures(e.point, {
              layers: ['clusters'],
            })
            const clusterId = features[0].properties.cluster_id
            mapInstance.getSource('stations').getClusterExpansionZoom(
              clusterId,
              function (err, zoom) {
                if (err) return

                mapInstance.easeTo({
                  center: features[0].geometry.coordinates,
                  zoom: zoom,
                })
              },
            )
          })

          mapInstance.on('mouseenter', 'clusters', function () {
            mapInstance.getCanvas().style.cursor = 'pointer'
          })
          mapInstance.on('mouseleave', 'clusters', function () {
            mapInstance.getCanvas().style.cursor = ''
          })
        }
      })
    }
  }, [stations, onStationSelected])

  useEffect(() => {
    if (!interactive && stations.length === 1 && mapRef.current) {
      const mapInstance = mapRef.current.getMap()

      if (zoom) mapInstance.setZoom(zoom)
      if (stations.length) {
        mapInstance.setCenter({
          lat: stations[0].lat,
          lng: stations[0].lng,
        })
      }
    }
  }, [zoom, interactive, stations])

  return <div className={ cn('map-wrapper', { interactive }) }>
    <Map
      ref={ mapRef }
      initialViewState={ {
        latitude: stations[0]?.lat || 41.3111519,
        longitude: stations[0]?.lng || 69.2797104,
        zoom: 5,
      } }
      mapStyle="mapbox://styles/mapbox/navigation-guidance-night-v4"
      attributionControl={ false }
    />
    { interactive && (
      <div className={ 'controls bottom-right all-stations' }>
        <Button focusable={ false } icon={ 'fit_screen' } onClick={ fitToStations }/>
      </div>
    ) }
  </div>
}
