import React from 'react'
import { Routes, Route } from 'react-router-dom'
import Users from './page/Users'
import Stations from './page/Stations'
import Inactive from './page/Inactive'
import NotFound from './page/NotFound'
import Profile from './page/Profile'
import Map from './page/Map'
import { useSessionContext } from './hooks/UseSession'
import { Navigate } from 'react-router-dom'
import ErrorLog from './page/errorLog'
import Logs from './page/logs'
import NewMap from './page/newMap'

const AppRoutes = () => {
  const { data: session, status } = useSessionContext()

  if (status === 'loading') {
    return <div>Loading...</div>
  }

  return (
    <Routes>
      <Route path="/" element={ <Navigate to="/map" replace/> }/>
      <Route path="/map" element={ <Map/> }/>
      <Route path="/tashbusmap" element={ <NewMap/> }/>
      <Route path="/users" element={ <Users/> }/>
      <Route path="/stations" element={ <Stations/> }/>
      <Route path="/inactive" element={ <Inactive/> }/>
      <Route path="/profile" element={ <Profile/> }/>
      <Route path="/errors" element={ <ErrorLog/> }/>
      <Route path="/logs" element={ <Logs/> }/>
      <Route path="*" element={ session ? <Navigate to="/map" replace/> : <NotFound/> }/>
    </Routes>
  )
}

const RoutesPage = () => {
  return <AppRoutes/>
}

export default RoutesPage
