import fetch from 'node-fetch'
import { useMemo, useState, useEffect } from 'react'
import { omit } from 'ramda'

async function rawFetcher(url, options) {
  let error
  try {
    const res = await fetch(url, options)
    if (res.status >= 400) {
      const json = await res.json()
      error = json ? JSON.stringify(json?.message) : 'Network error'
    } else {
      return res.json()
    }
  } catch (e) {
    console.error(`Fetch ${ url } error. ${ e.message }`)
    error = e.message
  }

  if (error) throw new Error(error)
}

async function apiFetcher(url, options) {
  let error

  try {
    const res = await fetch(url, options)
    if (res.status >= 400) {
      const json = await res.json()
      error = json ? JSON.stringify(json?.message) : 'Network error'
    } else {
      const headers = {}
      res.headers.forEach((value, key) => {
        headers[key] = value
      })

      return {
        data: await res.json(),
        headers,
      }
    }
  } catch (e) {
    console.error(`Fetch ${ url } error. ${ e.message }`)
    error = e.message
  }

  if (error) throw new Error(error)
}

function isFormData(data) {
  return data instanceof URLSearchParams || (typeof window !== 'undefined' && data instanceof window.FormData)
}

function fetcherPost(url, data, options = {}, appendHeaders = true) {
  const isFormDataObject = isFormData(data)
  const headers = appendHeaders
    ? {
      ...(isFormDataObject ? {} : { 'Content-Type': 'application/json' }),
      ...(options.headers || {}),
    }
    : options.headers || {}

  return apiFetcher(url, {
    method: 'POST',
    headers,
    redirect: 'follow',
    body: isFormDataObject ? data : JSON.stringify(data),
    ...omit(['headers'], options),
  })
}

function fetcherPut(url, data, options = {}) {
  const isFormDataObject = isFormData(data)

  return apiFetcher(url, {
    method: 'PUT',
    headers: {
      ...(isFormDataObject ? {} : { 'Content-Type': 'application/json' }),
      ...(options.headers || {}),
    },
    redirect: 'follow',
    body: isFormDataObject ? data : JSON.stringify(data),
    ...omit(['headers'], options),
  })
}

function useApi(url, defaultValue = null) {
  const [data, setData] = useState(defaultValue)
  const [error, setError] = useState(null)
  const [isValidating, setIsValidating] = useState(true)

  useEffect(() => {
    const fetchData = async () => {
      setIsValidating(true)
      try {
        const fetchedData = await rawFetcher(url)
        setData(fetchedData)
      } catch
        (error) {
        setError(error)
      } finally {
        setIsValidating(false)
      }
    }

    fetchData()
  }, [url])

  return useMemo(() => {
    if (isValidating || (!isValidating && error)) return defaultValue
    return data
  }, [data, error, isValidating, defaultValue])
}

export { apiFetcher, rawFetcher, fetcherPost, fetcherPut, useApi }
