import React, { useCallback } from 'react'
import BasePage from '../components/BasePage'
import { Button, Image, Layout, Card, Input, Row, ProgressButton, Col, Form, Message } from '@iqueue/ui-kit'
import { useSessionContext } from '../hooks/UseSession'
import Box from '../components/Box'
import withAuth from '../components/WithAuth'
import { changePassword } from '../api/Api'
import extractPlaceholder from '../utils/ExtractPlaceholder'
import { useNavigate } from 'react-router-dom'

const Profile = () => {
  const { signOut } = useSessionContext()
  const navigate = useNavigate()

  const { data: session } = useSessionContext()


  const handleSingOut = useCallback(() => {
    signOut()
    navigate('/')
  }, [navigate, signOut])

  if (!session) {
    return <div>Loading...</div>
  }

  return (
    <BasePage>
      <Box>
        <div>
          <Layout horizontal style={ { gridGap: 'var(--iq-grid-spacing)' } }>
            <Image
              alt={ session.user.displayName }
              className="user-avatar big"
              src={ session.user.photo || 'https://via.placeholder.com/150' }
              placeholder={ extractPlaceholder(session.user.displayName) }
            />
            <div>
              <h4>{ session.user.username }</h4>
              <h6>{ session.user.email }</h6>
              <Button onClick={ handleSingOut } danger>Выйти из аккаунта</Button>
            </div>
          </Layout>
        </div>

        <hr/>
        <Form onSubmit={ async (password) => {
          try {
            await changePassword(password)
          } catch (error) {
            Message({
              type: 'error',
              timeout: 5000,
              title: 'Ошибка'
            })
          }
        } }>
          <Row breakline="tablet">
            <Col size={ 6 }>
              <Card title="Аккаунт">
                <Row>
                  <Button size={ 12 } title="Привязанные аккаунты" disabled/>
                </Row>
                <Row>
                  <Button size={ 12 } title="Активные сессии" disabled/>
                </Row>
                <Row>
                  <Button size={ 12 } title="История действий" disabled/>
                </Row>
              </Card>
            </Col>
            <Col size={ 6 }>
              <Card title="Безопасность">
                <Row>
                  <Input size={ 12 } name="oldPassword" type="password" placeholder="Текущий пароль"/>
                </Row>
                <Row>
                  <Input size={ 12 } name="newPassword" type="password" placeholder="Новый пароль"/>
                </Row>
                <Row>
                  <Input size={ 12 } name="confirmPassword" type="password" placeholder="Повтор пароля"/>
                </Row>
                <Row>
                  <ProgressButton submit primary size={ 12 } style={ { marginBottom: 0 } }>
                    Сохранить
                  </ProgressButton>
                </Row>
              </Card>
            </Col>
          </Row>
        </Form>
      </Box>

    </BasePage>
  )
}

export default withAuth(Profile)
