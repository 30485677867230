import axios from 'axios'

const api = axios.create({
  baseURL: process.env.NODE_ENV === 'production'
    ? `${ window.location.protocol }//${ window.location.host }/api`
    : process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
})

export default api

export const refreshAuth = async () => {
  try {
    const session = JSON.parse(localStorage.getItem('session'))
    if (!session || !session.access_token) {
      throw new Error('No session or access token found')
    }

    const response = await api.post('/auth/refresh', {}, {
      headers: {
        Authorization: `Bearer ${ session.access_token }`,
      },
    })
    const newSession = {
      ...session,
      access_token: response.data.access_token,
      expireAt: new Date(response.data.expireAt).toISOString(),
    }

    localStorage.setItem('session', JSON.stringify(newSession))
    return newSession
  } catch (error) {
    console.error('Error refreshing auth:', error)
    throw error
  }
}

export const authenticatedRequest = async (url, options = {}) => {
  const session = JSON.parse(localStorage.getItem('session'))

  if (session && session.access_token) {
    options.headers = {
      ...options.headers,
      Authorization: `Bearer ${ session.access_token }`,
    }
  }

  try {
    const response = await api(url, options)
    return response.data
  } catch (error) {
    if (error.response && error.response.status === 401) {
      localStorage.removeItem('session')
      window.location.href = '/login'
      throw error
    } else {
      throw error
    }
  }
}

// Auth
export const login = async (username, password) => {
  try {
    const response = await api.post('/auth/login', { username, password })
    return response.data
  } catch (error) {
    console.error('Error logging in:', error)
    throw error
  }
}

export const changePassword = async (passwordData) => {
  try {
    const response = await authenticatedRequest('/users/change-password', {
      method: 'PATCH',
      data: passwordData,
    })
    return response.data
  } catch (error) {
    console.error('Error changing password:', error)
    throw error
  }
}

export const getProfile = async () => {
  try {
    const response = await authenticatedRequest('/auth/profile')
    return response.data
  } catch (error) {
    console.error('Error getting profile:', error)
    throw error
  }
}

// Users
export const createUser = async (userData) => {
  try {
    const response = await authenticatedRequest('/users', {
      method: 'POST',
      data: userData,
    })
    return response.data
  } catch (error) {
    console.error('Error creating user:', error)
    throw error
  }
}

export const getUsers = async () => {
  try {
    const response = await authenticatedRequest('/users')
    return response.data
  } catch (error) {
    console.error('Error getting users:', error)
    throw error
  }
}

export const toggleUserStatus = async (id) => {
  try {
    const response = await authenticatedRequest(`/users/toggle/${ id }`, {
      method: 'POST',
    })
    return response.data
  } catch (error) {
    console.error('Error toggling user status by ID:', error)
    throw error
  }
}

export const updateUser = async (id, userData) => {
  try {
    const response = await authenticatedRequest(`/users/${ id }`, {
      method: 'PATCH',
      data: userData,
    })
    return response.data
  } catch (error) {
    console.error('Error updating user:', error)
    throw error
  }
}

export const deleteUser = async (id) => {
  try {
    const response = await authenticatedRequest(`/users/${ id }`, {
      method: 'DELETE',
    })
    return response.data
  } catch (error) {
    console.error('Error deleting user:', error)
    throw error
  }
}

// Roles
export const getRoles = async () => {
  try {
    return await authenticatedRequest('/roles')
  } catch (error) {
    console.error('Error getting roles:', error)
    throw error
  }
}

// Stations

export const getStations = async () => {
  try {
    return await authenticatedRequest('/stations?perPage=50&page=1')
  } catch (error) {
    console.error('Error getting stations:', error)
    throw error
  }
}

export const getStationById = async (deviceId) => {
  try {
    return await authenticatedRequest(`/stations/${ deviceId }`)
  } catch (error) {
    console.error('Error getting station by ID:', error)
    throw error
  }
}

export const updateStation = async (deviceId, stationData) => {
  try {
    const response = await authenticatedRequest(`/stations/${ deviceId }`, {
      method: 'PATCH',
      data: stationData,
    })
    return response.data
  } catch (error) {
    console.error('Error updating station:', error)
    throw error
  }
}

export const deleteStation = async (deviceId) => {
  try {
    const response = await authenticatedRequest(`/stations/${ deviceId }`, {
      method: 'DELETE',
    })
    return response.data
  } catch (error) {
    console.error('Error deleting station:', error)
    throw error
  }
}

// Errors
export const getErrorDocs = async () => {
  try {
    return await authenticatedRequest('/error-docs')
  } catch (error) {
    console.error('Error fetching error docs:', error)
    throw error
  }
}

// Device Fonts
export const createDeviceFont = async (fontData) => {
  try {
    const response = await authenticatedRequest('/device-font', {
      method: 'POST',
      data: fontData,
    })
    return response.data
  } catch (error) {
    console.error('Error creating device font:', error)
    throw error
  }
}

export const getDeviceFonts = async () => {
  try {
    return await authenticatedRequest('/device-font')
  } catch (error) {
    console.error('Error getting device fonts:', error)
    throw error
  }
}

export const getDeviceFontById = async (id) => {
  try {
    const response = await authenticatedRequest(`/device-font/${ id }`)
    return response.data
  } catch (error) {
    console.error('Error getting device font by ID:', error)
    throw error
  }
}

export const updateDeviceFont = async (id, fontData) => {
  try {
    const response = await authenticatedRequest(`/device-font/${ id }`, {
      method: 'PATCH',
      data: fontData,
    })
    return response.data
  } catch (error) {
    console.error('Error updating device font:', error)
    throw error
  }
}

// Tashbus
export const getTashbusStations = async ({ lat, lng, km, bypassLocation = false } = {}) => {
  try {
    let url = '/tashbus/stations'
    if (!bypassLocation && lat && lng) {
      const queryParams = new URLSearchParams({ lat, lng, km }).toString()
      url = `/tashbus/stations?${ queryParams }`
    }
    return await authenticatedRequest(url)
  } catch (error) {
    console.error('Error getting Tashbus stations:', error)
    throw error
  }
}

export const getTashbusRoutes = async (stationId) => {
  try {
    return await authenticatedRequest(`/tashbus/routes/${ stationId }`)
  } catch (error) {
    console.error('Error getting Tashbus routes:', error)
    throw error
  }
}

export const getTashbusTypes = async () => {
  try {
    return await authenticatedRequest(`/stations/types`)
  } catch (error) {
    console.error('Error getting Tashbus routes:', error)
    throw error
  }
}

export const getTashbusStationById = async (stationId) => {
  console.log(stationId, 'stationId', 'EBASSSSS')
  try {
    return await authenticatedRequest(`/tashbus/stations/${ stationId }`)
  } catch (error) {
    console.error(`Error getting Tashbus station with ID ${ stationId }:`, error)
    throw error
  }
}


