export function handleFullScreen(DomNode) {
  const Component = DomNode
  if (Component.requestFullscreen) {
    Component.requestFullscreen()
  } else if (Component.webkitRequestFullscreen) {
    Component.webkitRequestFullscreen()
  } else if (Component.msRequestFullscreen) {
    Component.msRequestFullscreen()
  }
}
