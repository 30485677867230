import { createContext, useContext } from 'react'

const PropsContext = createContext()

export const PropsProvider = ({ children, ...props }) => {
  return (
    <PropsContext.Provider value={ props }>
      { children }
    </PropsContext.Provider>
  )
}

export const useProps = () => useContext(PropsContext)
