import React, { useRef, useState, useEffect, useCallback } from 'react'
import BasePage from '../components/BasePage'
import StationsMap from '../components/map/StationsMap'
import withAuth from '../components/WithAuth'
import { getStations, getTashbusRoutes } from '../api/Api'
import Dialog from '../components/Dialog'
import StationEvents from '../components/StationEvents'
import { Icon, Button, Message, Row, Col } from '@iqueue/ui-kit'
import io from 'socket.io-client'
import moment from 'moment'
import copyToClipboard from 'copy-to-clipboard'
import '../css/StationDialog.css'
import { useSessionContext } from '../hooks/UseSession'
import { debounce } from 'lodash'

const socket = process.env.NODE_ENV === 'production'
  ? io(`${ window.location.protocol === 'http:' ? 'ws:' : 'wss:' }//${ window.location.host }`)
  : io('ws://84.54.83.55:3333')

function getStatusIcon(station) {
  const { lastSeenAt, meta } = station
  const minutesSinceLastSeen = moment().diff(moment(lastSeenAt), 'minutes')
  if (meta?.lastData?.error) {
    return { icon: 'error_outline', color: 'red' }
  } else if (minutesSinceLastSeen > 30) {
    return { icon: 'warning', color: 'orange' }
  } else {
    return { icon: 'check_circle', color: 'green' }
  }
}

const debouncedUpdateStations = debounce((buffer, setStations) => {
  setStations((prevStations) => {
    const updatedStationsList = buffer.reduce((acc, parsedStation) => {
      const index = acc.findIndex((s) => s.deviceId === parsedStation.deviceId)
      if (index !== -1) {
        acc[index] = { ...acc[index], ...parsedStation }
      } else {
        acc.push(parsedStation)
      }
      return acc
    }, [...prevStations])

    return updatedStationsList.sort((a, b) => new Date(b.lastSeenAt) - new Date(a.lastSeenAt))
  })
  buffer.length = 0
}, 3000)

function Map() {
  const [station, setStation] = useState({})
  const [allStations, setAllStations] = useState([])
  const [recentStations, setRecentStations] = useState([])
  const bufferRef = useRef([])
  const { data: sessions } = useSessionContext()
  const showStationDialog = useRef()

  const { icon, color } = getStatusIcon(station)
  const formattedDate = moment(station.lastSeenAt).format('DD MMMM YYYY')
  const formattedTime = moment(station.lastSeenAt).format('HH:mm')
  const isAdmin = sessions?.user?.roles?.some((role) => role.name === 'admin')

  const handleStationSelected = useCallback(async (station) => {
    showStationDialog.current.open()
    setStation(station)
    try {
      await getTashbusRoutes(station.stationId)
    } catch (error) {
      console.error('Error fetching routes:', error)
    }
  }, [])

  const handleCopy = useCallback((text, message) => {
    copyToClipboard(text, { format: 'text/plain' })
    Message({
      type: 'info',
      timeout: 3000,
      title: message,
    })
  }, [])

  useEffect(() => {
    const fetchStations = async () => {
      try {
        const stationsData = await getStations()
        const filteredStations = stationsData.filter((station) => station.type?.name === 'prod')
        setAllStations(filteredStations.sort((a, b) => new Date(b.lastSeenAt) - new Date(a.lastSeenAt)))
        setRecentStations(filteredStations.slice(0, 15))
      } catch (error) {
        console.error('Error fetching stations:', error)
      }
    }
    fetchStations().catch((r) => console.error(r))
  }, [])

  useEffect(() => {
    const handleStationUpdated = async (updatedStation) => {
      let parsedStation
      try {
        parsedStation = JSON.parse(updatedStation)
      } catch (error) {
        console.error('Error parsing updatedStation:', error)
        return
      }
      bufferRef.current.push(parsedStation)
      debouncedUpdateStations(bufferRef.current, setAllStations)

      setRecentStations((prevRecentStations) => {
        const updatedRecentStations = [parsedStation, ...prevRecentStations.filter(s => s.deviceId !== parsedStation.deviceId)]
        if (updatedRecentStations.length > 15) {
          updatedRecentStations.pop()
        }
        return updatedRecentStations
      })
    }

    const handleApiLogged = async (logs) => {
      logs = JSON.parse(logs)
      if (logs.code > 399) {
        Message({
          type: 'error',
          timeout: 5000,
          title: `Ошибка API: ${ logs.code }`,
          subtitle: `URL: ${ logs.url }`,
        })
      }
    }

    socket.on('apiLogged', handleApiLogged)
    socket.on('stationUpdated', handleStationUpdated)
    return () => {
      socket.off('apiLogged', handleApiLogged)
      socket.off('stationUpdated', handleStationUpdated)
    }
  }, [])

  useEffect(() => {
    const intervalId = setInterval(() => {
      window.location.reload()
    }, 900000)

    return () => clearInterval(intervalId)
  }, [])

  return (
    <>
      <BasePage title="Карта" className="no-paddings no-scroll" scrollbars={ false }>
        <StationsMap stations={ allStations } onStationSelected={ handleStationSelected }/>
        <StationEvents stations={ recentStations } onStationSelected={ handleStationSelected }/>
      </BasePage>

        <Dialog className={'main-modal-map'} ref={ showStationDialog } title={ `${ station.name || '______' } / ${ station.stationId || '______' }` }>
          <div className="station-dialog">
            <div className="station-info">
              <div className="status-information">
                { station.lastSeenAt ? (
                  <div>
                    <Icon style={ { color, marginRight: '0.5rem' } }>{ icon }</Icon>
                    <span>
                    { formattedDate } { formattedTime }
                  </span>
                  </div>
                ) : (
                  ''
                ) }
              </div>
              { isAdmin && (
                <Row>
                  <Col size={ 6 }>
                    <Button onClick={ () => handleCopy(station.deviceId, 'Device ID был скопирован в буфер обмена!') }>
                      Device ID
                    </Button>
                  </Col>
                  <Col size={ 6 }>
                    <Button onClick={ () => handleCopy(station.stationId, 'Station ID был скопирован в буфер обмена!') }>
                      Station ID
                    </Button>
                  </Col>
                </Row>
              ) }
            </div>
            { !station.meta || station.meta?.lastData?.consoleView?.length === 0 ? (
              <b className={ 'info-none' }>Ничего тут нет</b>
            ) : (
              <div className="bus-list">
                { station.meta?.lastData?.consoleView.map((item, index) => (
                  <div key={ `route_${ index }` } className={ `bus-item ${ item.route === 'Err' ? 'error' : '' }` }>
                    <div className="bus-info">
                      <Icon className="icon" style={ { color: '#4caf50' } }>
                        directions_bus
                      </Icon>
                      <b>Маршрут: { item.route }</b>
                    </div>
                    <div className="arrival-time">
                      <Icon className="icon" style={ { color: '#ff9800' } }>
                        { item.route === 'Err' ? 'error_outline' : 'access_time_filled' }
                      </Icon>
                      <span style={ { fontWeight: '800' } }>{ item.remaining }</span>
                    </div>
                  </div>
                )) }
              </div>
            ) }
          </div>
        </Dialog>
    </>
  )
}

export default withAuth(Map, ['statistic', 'admin'])
