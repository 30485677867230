import { useRef, useEffect, useCallback, forwardRef, useImperativeHandle, useState } from 'react'
import { ls, PaginatedTable } from '@iqueue/ui-kit'
import qs from 'qs'
import { pick } from 'ramda'
import { apiFetcher } from '../utils/apiFetcher'
import { useSessionContext } from '../hooks/UseSession'

const fetches = {}

export function makeUrl(baseUrl, page, size, filter) {
  const glue = baseUrl.indexOf('?') !== -1 ? '&' : '?'

  const modifiedFilter = {
    ...filter,
    orderDirection: filter.orderDirection ? filter.orderDirection.toLowerCase() : undefined,
  }

  return `${baseUrl}${glue}${qs.stringify({
    page,
    perPage: size,
    ...modifiedFilter,
  }, {
    skipNulls: true,
  })}`
}

const defaultPageSizes = [25, 50, 100, 200, 500]

function BaseTable({
  baseUrl,
  storeKey,
  schema,
  minWidth,
  extra,
  defaultFilter = {},
  sortKey = 'createdAt',
  sortDirection = 'desc',
  ...props
}, ref) {
  if (!baseUrl || !storeKey) throw new Error('Properties "baseUrl" and "storeKey" are required!')

  const id = useRef(Date.now())
  const tableRef = useRef()
  const { data: session } = useSessionContext()

  const [pagination, setPagination] = useState({
    page: 1,
    size: 50,
    total: 0,
    ...(ls(`iq-table-${storeKey}-pagination`) || {}),
  })

  const [filter, setFilter] = useState({
    orderEntry: sortKey,
    orderDirection: sortDirection,
    ...defaultFilter,
  })

  useImperativeHandle(ref, () => ({
    refetch: () => tableRef.current.refetch(),
    setFilter: (filter) => {
      setFilter((current) => ({
        ...current,
        ...filter,
      }))
    },
    filter,
  }))

  useEffect(() => {
    if (storeKey) {
      ls(`iq-table-${storeKey}-pagination`, pick(['size'], pagination))
    }
  }, [pagination, storeKey])

  const fetch = useCallback(async (page, size) => {
    if (!fetches[id.current]) {
      const { data, headers } = await apiFetcher(makeUrl(baseUrl, page, size, filter), {
        headers: {
          'Authorization': `Bearer ${session.access_token}`,
        },
      })
      if (headers['Leerybit-Page'] !== undefined) {
        setPagination({
          page: Number(headers['Leerybit-Page']),
          size: Number(headers['Leerybit-Per-Page']),
          total: Number(headers['Leerybit-Per-Page']) * Number(headers['Leerybit-Pages']),
        })
      }

      delete fetches[id.current]
      return data
    }

    return fetches[id.current]
  }, [baseUrl, session.access_token, filter])

  const applySortKey = useCallback((key, dir) => {
    setFilter((current) => ({
      ...current,
      orderEntry: key,
      orderDirection: dir.toLowerCase(),
    }))
  }, [])

  return <PaginatedTable
    indexable
    dataSorted
    ref={tableRef}
    sortKey={sortKey}
    defaultSize={pagination.size || 50}
    defaultPage={pagination.page || 1}
    total={pagination.total}
    sizes={defaultPageSizes}
    entries={fetch}
    schema={schema}
    minWidth={minWidth}
    onSortKeyChanged={applySortKey}
    {...props}
  >
    {extra}
  </PaginatedTable>
}

export default forwardRef(BaseTable)
