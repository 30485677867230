import React, { useState } from 'react'
import {
  Window,
  Image,
  useTheme,
  useIsomorphicLayoutEffect,
  Modal, Row, Input, ProgressButton,
  Button,
} from '@iqueue/ui-kit'
import { useNavigate } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Head from 'react-helmet'
import cn from 'classnames'
import { AppTitle } from './AppHeader'
import withNav from './WithNav'
import { handleFullScreen } from '../utils/FullScreen'
import extractPlaceholder from '../utils/ExtractPlaceholder'
import { useSessionContext } from '../hooks/UseSession'

function BasePage(props) {
  const {
    children,
    nav,
    onNavigate,
    subtitle,
    title,
    footer,
    route,
    className,
    scrollbars = true,
    isShowFullScreenBtn = false,
  } = props
  const { setTheme } = useTheme()
  const navigate = useNavigate()

  const [authDialogOpened, setAuthDialogOpened] = useState(false)
  const { data: session, signIn, status } = useSessionContext()

  useIsomorphicLayoutEffect(() => {
    document.body.classList.add('iq-weak')
    const timeout = setTimeout(() => {
      document.body.classList.remove('iq-weak')
    }, 200)

    setTheme('dark')

    return () => {
      clearTimeout(timeout)
    }
  }, [setTheme])

  if (status === 'loading') {
    return <div>Loading...</div>
  }

  return (
    <Window
      className={ cn(className, 'radar-app with-scroll-bars') }
      keepHeader={ true }
      keepFooter={ true }
      scrollbars={ scrollbars }
      touchActions={ false }
      route={ route }
      onNavigate={ onNavigate }
      nav={ nav }
      title={ <AppTitle/> }
      subtitle={ subtitle }
      footer={ footer }
      extra={
        session && session.user ? (
          <span>
            <Image
              alt={ session.user.username }
              className="user-avatar"
              placeholder={ extractPlaceholder(session.user.username) }
              onClick={ () => navigate('/profile') }
            />
            { isShowFullScreenBtn && (
              <Button
                style={ { marginLeft: 5 } }
                flat
                icon="fullscreen"
                onClick={ () =>
                  handleFullScreen(
                    document.body.querySelector('.chart-container'),
                  )
                }
              />
            ) }
          </span>
        ) : (
          <ProgressButton
            primary
            flat
            style={ {
              width: '15rem',
            } }
            onClick={ () => setAuthDialogOpened(true) }
          >
            Вход в кабинет
          </ProgressButton>
        )
      }
    >
      <ToastContainer/>
      <Head>
        <title>{ 'Tashbus' + (title ? ' — ' + title : '') }</title>
      </Head>

      { session && session.user ? (
        children
      ) : (
        <Modal
          flatActions
          isOpened={ !!authDialogOpened }
          content={ authDialogOpened }
          onClose={ () => setAuthDialogOpened(false) }
          onApply={ async ({ username, password }) => {
            try {
              await signIn(username, password)
              setAuthDialogOpened(false)
            } catch (error) {
              console.log(error, 'error')
            }
          } }
          style={ {
            minWidth: '26rem',
          } }
          title="Авторизация"
          footerActions={ [
            {
              key: 'login',
              title: 'Авторизоваться',
              submit: true,
              primary: true,
            },
          ] }
        >
          <Row>
            <Input
              required
              autoFocus
              size={ 12 }
              name={ 'username' }
              placeholder={ 'Логин' }
            />
          </Row>
          <Row>
            <Input
              required
              size={ 12 }
              name={ 'password' }
              placeholder={ 'Пароль' }
              type={ 'password' }
            />
          </Row>
        </Modal>
      ) }
    </Window>
  )
}

export default withNav(BasePage)
