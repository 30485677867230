import React, { useMemo, useCallback } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { flatten } from 'ramda'
import { useSessionContext } from '../hooks/UseSession'

const iconForRoute = {
  'map': 'map',
  'stations': 'directions_bus',
  'settings': 'build',
  'billing': 'account_balance_wallet',
  'root': 'privacy_tip',
  'engineer': 'engineering',
  'users': 'groups',
  'administrative-divisions': 'public',
  'system-settings': 'tune',
  'realtime': 'track_changes',
  'history': 'query_stats',
  'collation': 'stacked_bar_chart',
  'reports': 'backup_table',
  'updates': 'extension',
  'api-booking': 'text_snippet',
  'integrations': 'api',
  'api-keys': 'vpn_key',
  'scenarios': 'rule',
  'locales': 'translate',
  'brand': 'design_services',
  'playlists': 'video_settings',
  'calendar': 'calendar_today',
  'operators': 'badge',
  'ticket': 'receipt_long',
  'tasks': 'account_tree',
  'exchange-rates': 'trending_up',
  'TashbusMap': 'map'
}

function capitalizeFirstLetter(string = '') {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

const groupsPerRole = {
  'admin': ['map', 'stations', 'users', 'tashbusmap'],
  'statistician': ['stations'],
}

export default function withNav(WrappedComponent) {
  const displayName = WrappedComponent.displayName || WrappedComponent.name || "Component"

  const ComponentWithNav = (props) => {
    const navigate = useNavigate()
    const location = useLocation()
    const route = useMemo(() => {
      return location.pathname.slice(1)
    }, [location])

    const { data: session } = useSessionContext()

    const onNavigate = useCallback((route) => {
      if (!location.pathname.includes(route)) {
        navigate(route, { replace: true })
      }
    }, [navigate, location])

    const nav = useMemo(() => {
      if (!session || !session.user || new Date(session.expireAt) - Date.now() <= 0) return null
      const { user } = session

      const groups = user.roles.reduce((acc, role) => {
        const roleGroups = groupsPerRole[role.name.toLowerCase()]
        if (roleGroups) {
          return acc.concat(roleGroups)
        }
        return acc
      }, [])

      if (groups.length === 0) return null

      const uniqueGroups = [...new Set(groups)]
      const firstGroup = []
      const nav = [firstGroup]

      uniqueGroups.forEach((item) => {
        if (Array.isArray(item)) {
          return
        }

        const navItem = {
          route: '/' + item,
          title: capitalizeFirstLetter(item),
          icon: iconForRoute[item],
        }

        if (route === item) {
          navItem.style = {
            backgroundColor: 'var(--iq-outline)',
            color: 'var(--iq-color)',
            pointerEvents: 'none',
            ...(navItem.style || {}),
          }
        }

        firstGroup.push(navItem)
      })

      return flatten(nav)
    }, [session, route])

    return <WrappedComponent nav={ nav } onNavigate={ onNavigate } route={ route } { ...props } />
  }

  ComponentWithNav.displayName = `withNav(${ displayName })`
  return ComponentWithNav
}
