import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'

// Get the root element from the DOM
const container = document.getElementById('root')

// Create a root using the createRoot method from react-dom/client
const root = ReactDOM.createRoot(container)

// Render the App component inside the root with React.StrictMode enabled
root.render(
  <React.StrictMode>
    <App/>
  </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
