import React, { useMemo, useRef, memo, useState, useCallback, useEffect } from 'react'
import moment from 'moment'
import { Input, Row, Select, Col, Icon, Message, noop } from '@iqueue/ui-kit'
import BaseTable from './BaseTable'
import Dialog from './Dialog'
import Box from './Box'
import LocationInput from './LocationInput'
import useSocket from '../hooks/UseSocket'
import CellNA from './CellNA'
import StationInput from './StationInput'
import { toString, prop, indexBy } from 'ramda'
import {
  updateStation,
  getTashbusStations,
  getDeviceFonts,
  deleteStation,
  getTashbusRoutes,
  getTashbusTypes,
} from '../api/Api'

function ListOfStations() {
  const addStationDialog = useRef()
  const tableRef = useRef()
  const [selectedStation, setSelectedStation] = useState(null)
  const [apiStations, setApiStations] = useState([])
  const [cords, setCords] = useState({})
  const [fonts, setFonts] = useState([])
  const [isSaved, setIsSaved] = useState(false)
  const [customCoordinates, setCustomCoordinates] = useState({ lat: null, lng: null })
  const [routes, setRoutes] = useState([])
  const [stationTypes, setStationTypes] = useState([])
  const refs = useRef({})
  const km = 1

  const cache = useMemo(() => {
    const ids = apiStations.map(station => toString(prop('stationId', station)))
    const indexed = indexBy(prop('stationId'), apiStations)

    return { ids, indexed }
  }, [apiStations])

  const handleDeleteStation = useCallback(async (deviceId) => {
    try {
      await deleteStation(deviceId)
      tableRef.current.refetch()
      addStationDialog.current.close()
    } catch (error) {
      console.error('Ошибка при удалении остановки:', error)
    }
  }, [])

  const calculateStatusColor = useCallback((station) => {
    if (!station.stationId) {
      return 'grey'
    }

    const lastSeenAt = station.lastSeenAt
    if (!lastSeenAt) {
      return 'purple'
    }

    const lastSeenMoment = moment(lastSeenAt)
    const now = moment()
    const duration = moment.duration(now.diff(lastSeenMoment))

    if (duration.asMinutes() <= 15) {
      return 'green'
    } else if (duration.asMinutes() <= 30) {
      return 'yellow'
    } else {
      return 'red'
    }
  }, [])

  const getStatusText = useCallback((color) => {
    switch (color) {
      case 'purple':
        return 'Нет активности'
      case 'yellow':
        return 'Не отвечает'
      case 'red':
        return 'Не отвечает более 30 минут'
      case 'green':
        return 'Работает исправно'
      case 'grey':
        return 'Не настроена'
      default:
        return ''
    }
  }, [])

  const schema = useMemo(() => {
    return [{
      key: 'name',
      title: 'Название',
      left: 0,
      filter: (value, item) => item.name ? item.name.toLocaleLowerCase().includes(value.toLocaleLowerCase()) : false,
      render: (v) => !v ? <CellNA/> : v,
    }, {
      key: 'deviceId',
      title: 'Серийный номер',
      width: '20rem',
      center: true,
    }, {
      key: 'type',
      title: 'Тип станции',
      width: '15rem',
      center: true,
      render: (_, v) => <span>{ v.type?.title }</span>,
    }, {
      key: 'status',
      title: 'Статус',
      width: '25rem',
      alignItems: 'center',
      render: (_, item) => {
        const color = calculateStatusColor(item)
        const gradientBackground = color === 'green'
          ? 'var(--iq-success)'
          : color === 'yellow'
            ? 'var(--iq-warning)'
            : color === 'red'
              ? 'var(--iq-error)'
              : color === 'grey'
                ? 'var(--iq-divider)'
                : 'var(--iq-accent-secondary)'

        const statusText = getStatusText(color)

        return <div style={ {
          background: gradientBackground,
          padding: '0.5rem 1rem',
          borderRadius: '0.5rem',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          height: '100%',
          color: '#fff',
          fontSize: '1.5rem',
          fontWeight: '600',
          textAlign: 'center',
          boxShadow: 'var(--iq-elevation-1-shadows)',
        } }>
          <span>{ statusText }</span>
        </div>
      },
    }, {
      key: 'lastSeenAt',
      title: 'Дата последнего запроса',
      width: '22rem',
      center: true,
      sort: noop,
      render: (v) => !v ? <CellNA/> : moment(v).format('lll'),
    }, {
      key: 'createdAt',
      title: 'Дата регистрации',
      width: '20rem',
      center: true,
      sort: noop,
      render: (v) => moment(v).format('lll'),
    }]
  }, [calculateStatusColor, getStatusText])

  const onNewRecord = useCallback((data) => {
    const view = refs.current[data.id]
    if (!view) return

    const count = Number.parseInt(view.dataset.count) + 1
    view.innerText = count
    view.dataset.count = count + ''
  }, [])

  useSocket('station:inc-record-counter', onNewRecord)

  const handleStationSelected = useCallback(async (station) => {
    setSelectedStation((prev) => ({ ...prev, ...station, deviceId: station.deviceId || prev?.deviceId }))
    addStationDialog.current.open()

    try {
      const routesData = await getTashbusRoutes(station.stationId)
      setRoutes(routesData)
    } catch (error) {
      console.log(error)
    }
  }, [])

  const handleSelectChange = useCallback(async (v) => {
    const selectedStationData = cache.indexed[v]

    if (selectedStationData) {
      setSelectedStation((prev) => ({
        ...prev,
        ...selectedStationData,
      }))

      if (selectedStationData.lat && selectedStationData.lng) {
        setCords({ lat: selectedStationData.lat, lng: selectedStationData.lng })
      } else {
        console.error('Invalid coordinates:', selectedStationData.lat, selectedStationData.lng)
      }

      try {
        const routesData = await getTashbusRoutes(selectedStationData.stationId)
        setRoutes(routesData)
      } catch (error) {
        console.error('Error fetching routes:', error)
      }
    } else {
      console.error(`Station data not found for ID: ${ v }`)
    }
  }, [cache])

  useEffect(() => {
    const abortController = new AbortController()
    const { signal } = abortController

    const fetchDeviceFont = async () => {
      try {
        const fonts = await getDeviceFonts({ signal })
        setFonts(fonts)
      } catch (err) {
        if (err.name !== 'AbortError') {
          console.error(err)
        }
      }
    }

    fetchDeviceFont().catch(err => console.error(err))

    return () => {
      abortController.abort()
    }
  }, [])

  useEffect(() => {
    const fetchStationTypes = async () => {
      try {
        const types = await getTashbusTypes()
        setStationTypes(types)
      } catch (error) {
        console.error('Ошибка при получении типов остановок:', error)
      }
    }

    fetchStationTypes().catch(res => console.log(res))
  }, [])

  useEffect(() => {
    const abortController = new AbortController()
    const { signal } = abortController

    const fetchStations = async () => {
      try {
        const data = await getTashbusStations({ lat: cords.lat, lng: cords.lng, km: km, signal })
        if (data) {
          setApiStations(data)
        }
      } catch (error) {
        if (error.name !== 'AbortError') {
          Message({
            type: 'error',
            timeout: 5000,
            title: 'Ошибка загрузки станций',
            subtitle: error.response?.data?.message || error.message,
          })
        }
      }
    }

    fetchStations().catch(err => console.error(err))

    return () => {
      abortController.abort()
    }
  }, [cords.lat, cords.lng, km])

  return <>
    <BaseTable
      ref={ tableRef }
      storeKey={ 'stations' }
      baseUrl={ process.env.NODE_ENV === 'production' ? `/api/stations` : 'http://84.54.83.55:3333/api/stations' }
      sortKey={ 'lastSeenAt' }
      sortDirection={ 'asc' }
      schema={ schema }
      minWidth={ '130rem' }
      onRowClick={ handleStationSelected }
    />

    <Dialog
      ref={ addStationDialog }
      title={ 'Редактирование остановки' }
      onApply={ async (data) => {
        setIsSaved(true)
        const deviceId = selectedStation?.deviceId || data.deviceId
        const payload = {
          ...data,
          ...selectedStation,
          lat: customCoordinates.lat,
          lng: customCoordinates.lng,
          typeId: data.stationType.id,
          deviceFontId: data.deviceFontId.id,
        }

        try {
          if (deviceId) {
            await updateStation(deviceId, payload)
            tableRef.current.refetch()
            addStationDialog.current.close()
          }
        } catch (error) {
          console.error('Ошибка при сохранении остановки:', error)
        }
      } }

      footerActions={ [{
        title: 'Удалить',
        danger: true,
        onClick: () => handleDeleteStation(selectedStation && selectedStation.deviceId),
      }, {
        title: 'Сохранить',
        submit: true,
        primary: true,
      }] }
    >
      <Box className={ 'small' }>
        <Row>
          <Input
            size={ 12 }
            required
            disabled={ !!selectedStation?.deviceId }
            name={ 'deviceId' }
            placeholder={ 'Серийный номер' }
            value={ selectedStation?.deviceId || '' }
          />
        </Row>
        <Row>
          <Input
            size={ 12 }
            disabled
            hidden
            name={ 'name' }
            placeholder={ 'Название остановки' }
            value={ selectedStation?.name || '' }
          />
        </Row>
        <Row>
          <Select
            size={ 12 }
            placeholder={ 'Шрифты' }
            name={ 'deviceFontId' }
            value={ selectedStation?.deviceFont }
            entries={ fonts }
            required
            render={ (item) => ({
              key: item.id,
              title: item.name,
            }) }
          />
        </Row>
        <Row>
          <Select
            size={ 12 }
            placeholder={ 'Тип остановки' }
            name={ 'stationType' }
            value={ selectedStation?.type }
            entries={ stationTypes }
            render={ (item) => ({
              key: item.id,
              title: item?.title,
            }) }
          />
        </Row>
        <Row>
          <StationInput
            size={ 12 }
            placeholder={ 'Остановка' }
            name={ 'stationId' }
            value={ selectedStation || '' }
            cache={ cache }
            onChange={ handleSelectChange }
          />
        </Row>
        <Row className="routes-preview">
          <Col size={ 12 }>
            <div className="routes-container">
              { selectedStation?.stationId && routes.consoleView && routes.consoleView.length !== 0 ? (
                routes.consoleView.map((routeGroup, groupIndex) => (
                  <div key={ groupIndex }
                       className={ `route-group ${ routeGroup.length === 1 ? 'single-route' : '' }` }>
                    { routeGroup.map((route, routeIndex) => (
                      <div key={ routeIndex } className="route">
                        <div className="main-buss">
                          <div className="bus-icon">
                            <Icon style={ { color: '#4caf50' } }>directions_bus</Icon>
                          </div>
                          <span className="route-number">Маршрут: { route.route }</span>
                        </div>
                        <div className="time-container">
                          <Icon className="time-icon" style={ { color: '#ff9800' } }>access_time</Icon>
                          <span className="route-remaining">{ route.remaining }</span>
                        </div>
                      </div>
                    )) }
                  </div>
                ))
              ) : (
                <span className="info-stations">Маршруты не найдены</span>
              ) }
            </div>
          </Col>
        </Row>

        <b style={ { textAlign: 'center' } }>Примерное место установки</b>
        <LocationInput
          onChange={ (item) => {
            setCords(item)
            setCustomCoordinates({ lat: item.lat, lng: item.lng })
          } }
          lat={ selectedStation?.lat }
          lng={ selectedStation?.lng }
          mapLocation={ selectedStation }
          stations={ apiStations }
          isModalOpen={ isSaved }
          onStationSelected={ handleStationSelected }
        />
      </Box>
    </Dialog>
  </>
}

export default memo(ListOfStations)
