import { Input, Icon } from '@iqueue/ui-kit'
import { useState, useRef, useCallback, useEffect } from 'react'
import GeoMap from './map/GeoMap'
import { always } from 'ramda'
import StationMarker from './map/marker/stationMarket'

export default function LocationInput({ onChange, lat, lng, mapLocation, onStationSelected, isModalOpen, ...props }) {
  const iconRef = useRef()
  const mapRef = useRef()

  const [stationLocation, setStationLocation] = useState({
    lat: lat || 41.3111519,
    lng: lng || 69.2797104,
    zoom: 14,
  })

  const [markerLocation, setMarkerLocation] = useState({
    lat: lat || 41.3111519,
    lng: lng || 69.2797104,
  })

  const [isStationSelected, setIsStationSelected] = useState(false)

  useEffect(() => {
    if ((isModalOpen || isStationSelected) && lat && lng) {
      setMarkerLocation({ lat, lng })
      setStationLocation((prev) => ({ ...prev, lat, lng }))

      if (mapRef.current) {
        mapRef.current.flyTo({
          center: [lng, lat],
          zoom: stationLocation.zoom,
          speed: 1,
        })
      }
      setIsStationSelected(false)
    }
  }, [lat, lng, isModalOpen, stationLocation.zoom, isStationSelected])

  useEffect(() => {
    if (mapLocation) {
      setIsStationSelected(true)
    }
  }, [mapLocation])

  useEffect(() => {
    if (isModalOpen && lat && lng) {
      setMarkerLocation({ lat, lng })
      setStationLocation((prev) => ({ ...prev, lat, lng }))

      if (mapRef.current) {
        mapRef.current.flyTo({
          center: [lng, lat],
          zoom: stationLocation.zoom,
          speed: 1,
        })
      }
    }
  }, [isModalOpen, lat, lng, stationLocation.zoom])

  const handleMarkerDragEnd = useCallback((event) => {
    const { viewState } = event
    const { latitude, longitude } = viewState

    setMarkerLocation({
      lat: latitude,
      lng: longitude,
    })

    onChange({ lat: latitude, lng: longitude })
  }, [onChange])

  const handleMarkerClick = useCallback((station) => {
    setMarkerLocation({
      lat: station.lat,
      lng: station.lng,
    })

    if (mapRef.current) {
      mapRef.current.flyTo({
        center: [station.lng, station.lat],
        zoom: stationLocation.zoom,
        speed: 0.5,
      })
    }

    if (onStationSelected) {
      onStationSelected(station)
    }
  }, [onStationSelected, stationLocation.zoom])

  const handleIdle = useCallback((e) => {
    const center = e.target.getCenter()
    const zoom = e.target.getZoom()

    setMarkerLocation({
      lat: +(center.lat.toFixed(6)),
      lng: +(center.lng.toFixed(6)),
    })

    setStationLocation((prevState) => ({
      ...prevState,
      zoom: zoom,
    }))

    onChange({ lat: center.lat, lng: center.lng })
  }, [onChange])

  const initialViewState = {
    latitude: lat || markerLocation.lat,
    longitude: lng || markerLocation.lng,
    zoom: stationLocation.zoom,
  }

  return (
    <div className="location-input-map-wrapper">
      <Input
        hidden
        name="lat"
        value={ stationLocation.lat }
        serialize={ Number.parseFloat }
        deserialize={ always(stationLocation.lat) }
      />
      <Input
        hidden
        name="lng"
        value={ stationLocation.lng }
        serialize={ Number.parseFloat }
        deserialize={ always(stationLocation.lng) }
      />

      <GeoMap
        ref={ mapRef }
        mapLocation={ mapLocation }
        id="pickLocationMap"
        initialViewState={ initialViewState }
        onDragStart={ () => iconRef.current.classList.add('preview') }
        onDragEnd={ handleMarkerDragEnd }
        onIdle={ handleIdle }
      >
        { props.stations.length !== 0 &&
          props.stations.map((station, index) => (
            <StationMarker
              key={ index }
              coordinates={ [station.lat, station.lng] }
              onClick={ () => handleMarkerClick(station) }
            />
          )) }
      </GeoMap>

      <div ref={ iconRef } className="cross">
        <Icon>fmd_good</Icon>
      </div>
    </div>
  )
}
