export default function ExtractPlaceholder(name) {
  if (!name) return 'G'

  if (name.indexOf(' ') !== -1) {
    const parts = name.split(' ')
    return [parts[0][0] || 'G', parts[1][0] || 'R']
  }

  return name[0] || 'G'
}
