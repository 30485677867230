import React from 'react'
import BasePage from '../components/BasePage'

const NotFound = () => (
  <BasePage>
      <h4>HELLO!</h4>
  </BasePage>
)

export default NotFound
