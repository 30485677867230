import React, { memo, useMemo, useRef, useCallback, useState, useEffect } from 'react'
import { Image, Button, Select, noop, Row, Input, Message, ProgressButton } from '@iqueue/ui-kit'
import moment from 'moment'
import copyToClipboard from 'copy-to-clipboard'
import BaseTable from './BaseTable'
import extractPlaceholder from '../utils/ExtractPlaceholder'
import Dialog from './Dialog'
import Box from './Box'
import { createUser, updateUser, getRoles, toggleUserStatus } from '../api/Api'
import { useSessionContext } from '../hooks/UseSession'

export const ROLE = {
  ADMIN: 'admin',
  STATISTICIAN: 'statistician',
}

export const ROLE_TITLE = {
  [ROLE.ADMIN]: 'Администратор',
  [ROLE.STATISTICIAN]: 'Статистик',
}

function PickUserRole({ name, value, onChange, roles, rolesTitle, ...props }) {
  return <Select
    value={ value }
    name={ name }
    entries={ roles }
    render={ (role) => {
      return {
        key: role.id,
        title: ROLE_TITLE[role.name],
      }
    } }
    onChange={ onChange }
    disabled={!roles || roles.length === 0}
    { ...props }
  />
}

function generatePassword(length) {
  if (length < 12) {
    throw new Error('Password length must be at least 8 characters')
  }

  const uppercase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
  const symbols = '!@#$%^&*()_+[]{}|;:,.<>?'
  const lowercase = 'abcdefghijklmnopqrstuvwxyz'
  const numbers = '0123456789'
  const allCharacters = uppercase + lowercase + numbers + symbols

  const getRandomCharacter = (characters) => characters[Math.floor(Math.random() * characters.length)]
  let password = getRandomCharacter(uppercase) + getRandomCharacter(symbols)

  for (let i = 2; i < length; i++) {
    password += getRandomCharacter(allCharacters)
  }

  password = password.split('').sort(() => 0.5 - Math.random()).join('')

  return password
}

function UpsertUser({ session }) {
  const [password, setPassword] = useState()
  const [roles, setRoles] = useState([])

  const regeneratePassword = useCallback(() => {
    setPassword(generatePassword(12))
  }, [])

  useEffect(() => {
    regeneratePassword()
  }, [regeneratePassword])

  useEffect(() => {
    const fetchRoles = async () => {
      setRoles(await getRoles())
    }

    fetchRoles().catch(res => console.error(res, 'RES'))
  }, [session])

  return <>
    <Row>
      <Input
        required
        autoFocus={ !session }
        size={ 12 }
        name={ 'username' }
        placeholder={ 'Логин' }
        value={ session?.username }
        disabled={ !!session }
      />
    </Row>

    { !session && (
      <Row>
        <Input
          size={ 12 }
          required
          name={ 'password' }
          placeholder={ 'Пароль' }
          value={ password }
          actions={ [{
            key: 'refresh',
            icon: 'refresh',
            onClick: regeneratePassword,
          }, {
            key: 'copy',
            icon: 'copy',
            onClick: () => {
              copyToClipboard(password, { format: 'text/plain' })

              Message({
                type: 'info',
                timeout: 5000,
                title: 'Пароль был скопирован в буфер обмена!',
              })
            },
          }] }
        />
      </Row>
    ) }

    <Row>
      <Input
        autoFocus={ !!session }
        size={ 12 }
        required
        name={ 'displayName' }
        placeholder={ 'ФИО' }
        value={ session?.displayName }
      />
    </Row>

    <Row>
      <Input
        size={ 12 }
        name={ 'phone' }
        placeholder={ 'Телефон' }
        value={ session?.phone }
      />
    </Row>

    <Row>
      <PickUserRole
        size={ 12 }
        name={ 'role' }
        placeholder={ 'Роль' }
        roles={ roles }
        required
        value={ session?.roles[0] || '' }
      />
    </Row>
  </>
}

function ListOfUsers() {
  const addAccountDialog = useRef()
  const tableRef = useRef()
  const [selectedUser, setSelectedUser] = useState({})
  const { data: session } = useSessionContext()

  const handleButtonClick = useCallback(async (id) => {
    await toggleUserStatus(id)
    tableRef.current.refetch()
  }, [])

  const schema = useMemo(() => {
      return [{
        key: 'displayName',
        title: 'Сотрудник',
        render: (v, item) => (
          <div>
            <Image
              alt={ v }
              className={ 'table-user-avatar' }
              src={ item.image }
              placeholder={ extractPlaceholder(v) }
            />
            <span>{ v }</span>
          </div>
        ),
      }, {
        key: 'username',
        title: 'Логин',
        width: '20rem',
        render: (v) => <span>{ v }</span>,
      }, {
        key: 'roles',
        title: 'Роль',
        width: '17rem',
        center: true,
        render: (_, item) => <span>{ ROLE_TITLE[item?.roles[0].name] }</span>,
      }, {
        key: 'enabled',
        title: 'Статус',
        width: '14rem',
        center: true,
        render: (enabled, item) => {
          return (
            <>
              <div onClick={ (e) => {
                e.stopPropagation()
              } }>
                <ProgressButton
                  className={ 'status_button' }
                  primary={ !enabled }
                  danger={ enabled }
                  disabled={ item.id === session.user.id }
                  onClick={ () => handleButtonClick(item.id, enabled, tableRef) }
                >
                  { enabled ? 'Закрыть доступ' : 'Открыть доступ' }
                </ProgressButton>
              </div>
            </>
          )
        },
      }, {
        key: 'createdAt',
        title: 'Дата регистрации',
        width: '20rem',
        center: true,
        sort: noop,
        render: (v) => moment(v).format('lll'),
      }]
    },
    [handleButtonClick, session.user.id],
  )

  return <>
    <BaseTable
      ref={ tableRef }
      className={ 'selectable' }
      storeKey={ 'accounts' }
      baseUrl={ process.env.NODE_ENV === 'production' ? `/api/users` : 'http://84.54.83.55:3333/api/users' }
      schema={ schema }
      sortDirection={ 'desc' }
      minWidth={ '110rem' }
      onRowClick={ (account) => {
        setSelectedUser(account)
        addAccountDialog.current.open()
      } }
      extra={ (
        <Button
          primary
          onClick={ () => {
            addAccountDialog.current.open()
            setSelectedUser(undefined)
          } }
          title={ 'Добавить сотрудника' }
        />
      ) }
    />

    <Dialog
      ref={ addAccountDialog }
      title={ selectedUser ? 'Редактирование сотрудника' : 'Добавление сотрудника' }
      onApply={ async (data) => {
        const result = {
          ...data,
          roleId: data.role.id,
        }

        if (selectedUser) {
          await updateUser(result.id, result)
        } else {
          await createUser(result)
        }
        tableRef.current.refetch()
      } }
      footerActions={ [{
        title: 'Сохранить',
        submit: true,
        primary: true,
      }] }
    >
      { !!selectedUser && <Input name={ 'id' } value={ selectedUser?.id } hidden/> }

      <Box className={ 'small' }>
        <UpsertUser
          session={ selectedUser }
        />
      </Box>
    </Dialog>
  </>

}

export default memo(ListOfUsers)


