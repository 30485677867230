import { useMemo, useCallback, useRef, useEffect } from 'react'
import { absorbEvent, Button } from '@iqueue/ui-kit'
import { LngLatBounds } from 'mapbox-gl'
import cn from 'classnames'
import GeoMap from './GeoMap'
import StationMarker from './marker/stationMarket'

export default function StationsMap({
  stations = [],
  interactive = true,
  storeKey = 'stations-map',
  zoom,
  onStationSelected,
}) {
  const markersRef = useRef({})
  const mapRef = useRef(null)

  const markers = useMemo(() => {
    if (!stations) return null

    return stations.map((station) => (
      <StationMarker
        key={ station.id }
        ref={ (el) => (markersRef.current[station.stationId] = el) }
        coordinates={ [station.lat, station.lng] }
        onClick={ () => onStationSelected(station) }
        error={ station.meta?.lastData?.error }
        station={ station }
      />
    ))
  }, [stations, onStationSelected])

  const fitToStations = useCallback((e, options = {}) => {
    absorbEvent(e)

    if (!stations.length || !mapRef.current) {
      console.warn('fitToStations: No stations or map not initialized')
      return
    }

    const bounds = stations.reduce((bounds, { lat, lng }) => {
      const location = [lng, lat]
      if (!bounds) {
        return new LngLatBounds(location, location)
      }
      return bounds.extend(location)
    }, null)

    if (bounds) {
      const bottomDivHeight = document.querySelector('.bottom-div')?.offsetHeight || 0

      const padding = {
        top: Math.min(window.outerHeight, window.outerWidth) * 0.1,
        bottom: bottomDivHeight + Math.min(window.outerHeight, window.outerWidth) * 0.3,
        left: Math.min(window.outerHeight, window.outerWidth) * 0.2,
        right: Math.min(window.outerHeight, window.outerWidth) * 0.2,
      }
      mapRef.current.fitBounds(bounds, { ...options, padding })
    }
  }, [stations, mapRef])

  useEffect(() => {
    if (!interactive && stations.length === 1 && mapRef.current) {
      if (zoom) mapRef.current.setZoom(zoom)
      if (stations.length) {
        mapRef.current.setCenter({
          lat: stations[0].lat,
          lng: stations[0].lng,
        })
      }
    }
  }, [zoom, interactive, stations, mapRef])

  return (
    <div className={ cn('map-wrapper', { interactive }) }>
      <GeoMap ref={ mapRef } id={ 'stationMap' } storeKey={ storeKey }>
        { markers }
        { interactive && (
          <div className={ 'controls bottom-right' }>
            <Button focusable={ false } icon={ 'fit_screen' } onClick={ fitToStations }/>
          </div>
        ) }
      </GeoMap>
    </div>
  )
}
