import { useEffect } from 'react'

const useSocket = (eventName, callback) => {
  useEffect(() => {
    const handleEvent = (event) => {
      callback(event.detail)
    }

    window.addEventListener(eventName, handleEvent)

    return () => {
      window.removeEventListener(eventName, handleEvent)
    }
  }, [eventName, callback])
}

export default useSocket
