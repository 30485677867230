import { useMemo, forwardRef, useCallback, useState, useImperativeHandle } from 'react'
import { noop, ls } from '@iqueue/ui-kit'
import Map from 'react-map-gl'

const GeoMap = forwardRef(({ storeKey, onIdle = noop, stationLocation, ...props }, ref) => {
  const [mapRef, setMapRef] = useState(null)

  const mapStyle = useMemo(() => {
    return 'mapbox://styles/mapbox/navigation-guidance-night-v4'
  }, [])

  const initialViewState = useMemo(() => {
    const defaultState = {
      latitude: stationLocation?.lat || 41.3111519,
      longitude: stationLocation?.lng || 69.2797104,
      zoom: 15,
    }
    return storeKey ? (ls(`geo-map-${ storeKey }`) || defaultState) : defaultState
  }, [stationLocation, storeKey])

  const onMapIdle = useCallback((e) => {
    if (storeKey) {
      ls(`geo-map-${ storeKey }`, {
        latitude: e.target.getCenter().lat,
        longitude: e.target.getCenter().lng,
        zoom: e.target.getZoom(),
      })
    }
    onIdle(e)
  }, [onIdle, storeKey])

  useImperativeHandle(ref, () => mapRef, [mapRef])

  return <Map
    ref={ setMapRef }
    initialViewState={ initialViewState }
    mapStyle={ mapStyle }
    attributionControl={ false }
    onIdle={ onMapIdle }
    { ...props }
  />
})

export default GeoMap
