import React, { createContext, useContext, useState, useEffect, useCallback } from 'react'
import api, { refreshAuth, login } from '../api/Api'
import moment from 'moment'

const SessionContext = createContext(undefined)

export const SessionProvider = ({ children }) => {
  const [session, setSession] = useState(null)
  const [status, setStatus] = useState('loading')

  const signOut = useCallback(() => {
    setSession(null)
    setStatus('unauthenticated')
    localStorage.removeItem('session')
    window.location.href = '/login'
  }, [])

  const signIn = useCallback(async (username, password) => {
    try {
      const response = await login(username, password)
      const { access_token, expireAt } = response
      const newSession = {
        access_token,
        expireAt: new Date(expireAt).toISOString(),
        user: response.user,
      }
      localStorage.setItem('session', JSON.stringify(newSession))

      const profileResponse = await api.get('/auth/profile', {
        headers: { Authorization: `Bearer ${ access_token }` },
      })

      newSession.user = profileResponse.data

      setSession(newSession)
      setStatus('authenticated')
      localStorage.setItem('session', JSON.stringify(newSession))
    } catch (error) {
      console.error('Ошибка при входе в систему:', error)
      throw error
    }
  }, [])

  useEffect(() => {
    const savedSession = JSON.parse(localStorage.getItem('session'))
    if (savedSession && moment(savedSession.expireAt).isAfter(moment())) {
      setSession(savedSession)
      setStatus('authenticated')
    } else {
      localStorage.removeItem('session')
      setStatus('unauthenticated')
    }
  }, [])

  useEffect(() => {
    const interval = setInterval(async () => {
      const currentSession = JSON.parse(localStorage.getItem('session'))
      if (currentSession && moment().isSameOrBefore(moment(currentSession.expireAt), 'day')) {
        try {
          const newSession = await refreshAuth()
          setSession(newSession)
          localStorage.setItem('session', JSON.stringify(newSession))
        } catch (error) {
          console.error('Ошибка при обновлении токена:', error)
          signOut()
        }
      }
    }, 600 * 1000)

    return () => clearInterval(interval)
  }, [signOut])

  useEffect(() => {
    const interceptor = api.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response && error.response.status === 401) {
          signOut()
        }
        return Promise.reject(error)
      },
    )

    return () => api.interceptors.response.eject(interceptor)
  }, [signOut])

  return (
    <SessionContext.Provider value={ { data: session, status, signOut, signIn } }>
      { children }
    </SessionContext.Provider>
  )
}

export const useSessionContext = () => useContext(SessionContext)
