import React, { memo, useMemo, useRef, useCallback } from 'react'
import { noop } from '@iqueue/ui-kit'
import moment from 'moment'
import BaseTable from './BaseTable'

function ListOfLogs() {
  const tableRef = useRef()

  const getColorForCode = useCallback((code) => {
    return code < 399 ? 'green' : 'red'
  }, [])

  const schema = useMemo(() => {
    return [{
      key: 'url',
      title: 'URL',
      sort: noop,
      render: (v) => <span>{ v }</span>,
    }, {
      key: 'code',
      title: 'Code',
      width: '6rem',
      center: true,
      sort: noop,
      render: (v) => {
        const color = getColorForCode(v)
        const gradientBackground = color === 'green'
          ? 'var(--iq-success)'
          : 'var(--iq-error)'

        return (
          <div style={ {
            background: gradientBackground,
            padding: '0.5rem 1rem',
            borderRadius: '0.5rem',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '100%',
            color: '#fff',
            fontSize: '1.2rem',
            fontWeight: '600',
            textAlign: 'center',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)',
            transition: 'background 0.5s ease',
          } }>
            <span>{ v }</span>
          </div>
        )
      },
    }, {
      key: 'createdAt',
      title: 'Дата регистрации',
      width: '20rem',
      center: true,
      sort: noop,
      render: (v) => moment(v).format('lll'),
    }].filter(v => !!v)
  }, [getColorForCode])

  return <BaseTable
    ref={ tableRef }
    className={ 'selectable' }
    storeKey={ 'api-logs' }
    baseUrl={ process.env.NODE_ENV === 'production' ? `/api/api-logs` : 'http://84.54.83.55:3333/api/api-logs' }
    schema={ schema }
    sortDirection={'desc'}
    minWidth={ '80rem' }
  />
}

export default memo(ListOfLogs)
