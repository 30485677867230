import { forwardRef } from 'react'
import cn from 'classnames'

function Box({ className, ...props }, ref) {
  return <div
    ref={ ref }
    className={ cn(className, 'content-box') }
    { ...props }
  />
}

export default forwardRef(Box)
