import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { SessionProvider } from './hooks/UseSession'
import { PropsProvider } from './hooks/UseProps'
import withNav from './components/WithNav'
import RoutesPage from './RoutesPage'

import 'moment/locale/ru'
import './components/map/mapbox-gl.css'
import 'react-photo-view/dist/react-photo-view.css'
import '@iqueue/ui-kit/lib/ui-kit.css'
import '@iqueue/ui-kit/lib/icons.css'
import '@iqueue/ui-kit/lib/roboto.css'
import 'react-circular-progressbar/dist/styles.css'
import './App.scss'

const AppWithNav = withNav(RoutesPage)

function App({ serverIp, ...props }) {
  return (
    <SessionProvider>
      <PropsProvider>
        <Router>
          <AppWithNav { ...props } />
        </Router>
      </PropsProvider>
    </SessionProvider>
  )
}

export default App
