import React from 'react'
import BasePage from '../components/BasePage'
import Box from '../components/Box'
import withAuth from '../components/WithAuth'
import ListOfUsers from '../components/ListOfUsers'

const Users = ({ session }) => (
  <BasePage className="no-paddings no-scroll">
    <Box>
      <ListOfUsers user={ session }/>
    </Box>
  </BasePage>
)

export default withAuth(Users, ['admin', 'owner', 'installer', 'tuner'])
