import React, { useCallback } from 'react'
import { Icon } from '@iqueue/ui-kit'
import moment from 'moment'
import '../css/StationEvents.css'

const StationEvents = ({ stations, onStationSelected, isDialogOpen }) => {
  const formatDateTime = useCallback((time) => {
    const date = moment(time)
    return {
      date: date.format('DD MMMM'),
      time: date.format('HH:mm'),
    }
  }, [])

  const getCardStatus = useCallback((lastSeenAt) => {
    const minutesSinceLastSeen = moment().diff(moment(lastSeenAt), 'minutes')
    if (minutesSinceLastSeen > 60) {
      return 'warning'
    } else {
      return 'active'
    }
  }, [])

  const filteredStations = stations.filter(station => station.lastSeenAt)

  return (
    <div className={ `station-events ${ isDialogOpen ? 'dialog-open' : 'dialog-close' }` }>
      { filteredStations.length > 0 ? (
        filteredStations.map((station, index) => {
          const { date, time } = formatDateTime(station.lastSeenAt)
          const isError = station.meta?.lastData?.error
          const cardStatus = isError ? 'inactive' : getCardStatus(station.lastSeenAt)

          return (
            <div
              key={ index }
              className={ `event-item ${ cardStatus }` }
              onClick={ () => onStationSelected(station) }
            >
              <div className="event-details">
                <div className="event-title">{ station.name }</div>
                <div className="event-date-time">
                  <span className="event-date">{ date }</span>
                  <span className="event-time">{ time }</span>
                </div>
                { station.meta?.lastData?.consoleView && station.meta.lastData.consoleView.length > 0 ? (
                  <div className="event-routes">
                    { station.meta.lastData.consoleView.map((route, idx) => (
                      <div key={ idx } className="event-route">
                        <div className="route-info">
                          <div className="bus-icon">
                            <Icon style={ { color: '#fff' } }>directions_bus</Icon>
                          </div>
                          <span className="route-number">{ route.route }</span>
                          <div className={ `time-icon ${ isError ? 'error' : '' }` }>
                            <Icon style={ { color: '#fff' } }>
                              { isError ? 'error' : 'access_time' }
                            </Icon>
                          </div>
                          <span className="route-time">{ route.remaining }</span>
                        </div>
                      </div>
                    )) }
                  </div>
                ) : (
                  <div className="no-routes">Маршрутов нет</div>
                ) }
              </div>
            </div>
          )
        })
      ) : (
        <div className="no-stations">Нет доступных станций</div>
      ) }
    </div>
  )
}

export default StationEvents
