import React, { useState, useEffect } from 'react'
import BasePage from '../components/BasePage'
import { getErrorDocs } from '../api/Api'
import '../css/ErrorLog.css'

const ErrorLog = () => {
  const [errorDocs, setErrorDocs] = useState([])

  useEffect(() => {
    const fetchErrorDocs = async () => {
      try {
        const docs = await getErrorDocs()
        setErrorDocs(docs)
      } catch (error) {
        console.error('Error fetching error docs:', error)
      }
    }

    fetchErrorDocs().catch(err => console.error(err))
  }, [])

  return <BasePage>
    <div className="error-log-container">
      { errorDocs.map((item) => (
        <div className="error-card" key={ item.key }>
          <div className="error-title">{ item.value }</div>
          <div className="error-subtitle">{ item.key }</div>
        </div>
      )) }
    </div>
  </BasePage>
}

export default ErrorLog
