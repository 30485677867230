import React from 'react'
import BasePage from '../components/BasePage'
import Box from '../components/Box'
import ListOfStations from '../components/ListOfStations'
import withAuth from '../components/WithAuth'

const Stations = () => (
  <BasePage className="no-paddings no-scroll">
    <Box>
      <ListOfStations />
    </Box>
  </BasePage>
)

export default withAuth(Stations, ['admin', 'owner', 'installer', 'tuner'])
